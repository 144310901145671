<template>
  <b-media-aside v-if="isLoading || imageUrl">
    <div
      v-if="isLoading"
      class="rounded mr-2 mb-1 mb-md-0 border d-flex align-items-center justify-content-center"
      style="width: 90px; height: 90px"
    >
      <b-spinner variant="primary" />
    </div>
    <div v-else>
      <b-avatar
        :src="imageUrl"
        :icon="'laptop'"
        :variant="`outline-primary`"
        class="p-1"
        size="90"
        rounded
        badge-top
        badge-variant="danger"
        badge-offset="-7px"
        button
        @click.prevent="deleteFile"
      >
        <template #badge>
          <feather-icon
            icon="XIcon"
            size="11"
          />
        </template>
      </b-avatar>
    </div>
  </b-media-aside>
</template>

<script>
import {
  BMediaAside,
  BSpinner,
  BAvatar,
  BIconLaptop,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BMediaAside,
    BSpinner,
    BAvatar,
    BIconLaptop,
  },
  directives: {
    Ripple,
  },
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const deleteFile = () => {
      emit('delete-file', props.imageUrl)
    }

    return {
      deleteFile,
    }
  },
}
</script>

<style scoped>
  .b-avatar >>> .b-avatar-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px !important;
  }
</style>
