import S3 from 'aws-s3'

export const s3FileUploader = {
  data() {
    return {
      uploadedFile: { name: this.obj[this.fieldName] },
      isLoading: false,
    }
  },
  computed: {
    config() {
      return {
        bucketName: process.env.VUE_APP_S3_BUCKET_NAME,
        dirName: this.directory, /* optional */
        region: process.env.VUE_APP_S3_REGION,
        accessKeyId: process.env.VUE_APP_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.VUE_APP_S3_SECRET_ACCESS_KEY,
        s3Url: process.env.VUE_APP_S3_BASE_URL, /* optional */
      }
    },
    S3Client() {
      return new S3(this.config)
    },
    baseUrl() {
      return `${process.env.VUE_APP_S3_BASE_URL}${this.directory}`
    },
  },
  methods: {
    uploadFile(e) {
      const file = e.target.files[0]
      this.isLoading = true

      this.S3Client
        .uploadFile(file, file.name.split('.')[0])
        .finally(() => {
          const fileExtension = file.type.split('/')[1]

          this.obj[this.fieldName] = `${this.baseUrl}/${file.name.split('.')[0]}.${fileExtension}`

          this.isLoading = false
        })
    },
    deleteFile() {
      const file = this.uploadedFile.name
      this.isLoading = true

      this.S3Client
        .deleteFile(file)
        .then(response => console.log(response))
        .catch(err => console.error(err))
        .finally(() => {
          this.isLoading = false
          this.obj[this.fieldName] = ''
          this.uploadedFile = { name: '' }
        })
    },
  },
}

export const _ = null
