<template>
  <div>
    <label
      v-if="label"
      class="text-capitalize"
    >
      {{ label }}
    </label>

    <b-media
      no-body
      vertical-align="center"
      class="flex-column flex-md-row"
    >

      <s-3-image-preview
        :image-url="obj[fieldName]"
        :is-loading="isLoading"
        @delete-file="deleteFile"
      />

      <s-3-image-input
        v-if="!obj[fieldName]"
        :accept="accept"
        @upload-file="uploadFile"
      />
    </b-media>
  </div>
</template>

<script>
import { BMedia } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import S3ImagePreview from './s3ImagePreview.vue'
import S3ImageInput from './s3ImageInput.vue'
import { s3FileUploader } from '@/mixins/s3/s3FileUploader'

export default {
  components: {
    BMedia,
    S3ImagePreview,
    S3ImageInput,
  },
  directives: {
    Ripple,
  },
  mixins: [s3FileUploader],
  props: {
    accept: {
      type: String,
      required: true,
    },
    obj: {
      type: Object,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    directory: {
      type: String,
      required: true,
    },
  },
}
</script>
