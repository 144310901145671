<template>
  <b-media-body class="align-self-center">
    <div
      class="d-flex border border-primary rounded justify-content-center align-items-center"
      style="width: 90px; height: 90px; border-style: dotted !important;"
    >
      <b-button
        v-ripple.400="'rgba(255, 206, 38, 0.15)'"
        variant="primary"
        size="sm"
        class="m-2"
        @click="$refs.refInputEl.click()"
      >
        <input
          ref="refInputEl"
          type="file"
          class="d-none"
          :accept="accept"
          @input="uploadFile"
        >
        <feather-icon
          icon="UploadCloudIcon"
          size="12"
        />
        <small class="align-middle">Upload</small>
      </b-button>
    </div>

  </b-media-body>
</template>

<script>
import {
  BMediaBody,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BMediaBody,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    accept: {
      type: String,
      required: true,
    },
  },
  setup(_, { emit }) {
    const uploadFile = e => {
      emit('upload-file', e)
    }

    return {
      uploadFile,
    }
  },
}
</script>

<style scoped>
  .b-avatar >>> .b-avatar-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px !important;
  }
</style>
